<template>
  <div v-if="studentInfo" class="mb-4" v-cloak>
    <p class="text-h5 font-weight-light mt-3">Мой профиль</p>
    <v-divider></v-divider>
    <v-row class="mt-7" justify="center" align="center">
      <v-card 
        :class="$vuetify.breakpoint.mdAndUp ? 'px-1' : 'mx-3'" 
        flat
        tile 
        outlined 
        :width="$vuetify.breakpoint.mdAndUp ? '80%' : '95%'"
      >
        <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
          <v-toolbar-title v-if="!isGeneralEditing" class="text-body-1 font-weight-half-medium pl-0">
            <v-icon left color="#00AEBA" size="25" class="pb-1">mdi-account-outline</v-icon>
            <span>{{fullName}}</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn :color="isGeneralEditing ? '#e31e25' : '#7690c9'" text dark x-small :disabled="isContactsEditing || isParentsEditing || isChangingPassword" @click="generalEditButton">
            <v-icon left dark>{{isGeneralEditing ? 'mdi-window-close' : 'mdi-pencil'}}</v-icon>
            {{isGeneralEditing ? 'Отмена' : 'Редактировать'}}
          </v-btn>
        </v-app-bar>
        <v-divider />
        <div v-if="!isGeneralEditing" class="pl-4 my-4">
          <div>
            <span class="text-body-1 font-weight-half-medium">
              Пол:
            </span>
            <span class="text-body-1">
              {{studentInfo.gender ? studentInfo.gender : 'Не указан'}}
            </span>
          </div>
          <div>
            <span class="text-body-1 font-weight-half-medium">
              Дата рождения:
            </span>
            <span class="text-body-1">
              {{concatedBirthdayInfo}}
            </span>
          </div>
          <div>
            <span class="text-body-1 font-weight-half-medium">
              СНИЛС:
            </span>
            <span class="text-body-1">
              {{formatedSnils}}
            </span>
          </div>
        </div>
        <v-form v-else class="mx-5 mt-4" v-model="isGeneralValid">
          <v-row justify="center" align="center">
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
              <v-text-field
                v-model="studentInfoForEdit.lastName"
                :rules="[rules.required,  rules.maxSymbFullName]"
                label="Фамилия"
                color="#af955c"
                dense
                required
                :error="!studentInfoForEdit.lastName"
                :readonly="!isGeneralEditing"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
              <v-text-field
                v-model="studentInfoForEdit.firstName"
                :rules="[rules.required,  rules.maxSymbFullName]"
                label="Имя"
                color="#af955c"
                dense
                required
                :error="!studentInfoForEdit.firstName"
                :readonly="!isGeneralEditing"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
              <v-text-field
                v-model="studentInfoForEdit.middleName"
                :rules="[rules.maxSymbMiddleName]"
                label="Отчество"
                hint="Введите отчество"
                dense
                color="#af955c"
                :readonly="!isGeneralEditing"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="start" align="center">
            <v-col cols="12" sm="6" md="4" lg="4" xl="4">
              <v-select
                v-model="studentInfoForEdit.gender"
                :items="genders"
                :rules="[rules.required]"
                label="Пол"
                color="#af955c"
                dense
                :error="!studentInfoForEdit.gender"
                required
                :readonly="!isGeneralEditing"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" xl="4">
              <v-text-field
                  v-model="studentInfoForEdit.birthdayDate"
                  label="Дата рождения"
                  hint="Укажите дату рождения"
                  color="#af955c"
                  dense
                  type="date"
                  min="1900-01-01" 
                  max="2200-12-31"
                  :value="studentInfoForEdit.birthdayDate"
                  :error="!studentInfoForEdit.birthdayDate"
                  required
                  :disabled="true"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="4" xl="4">
              <v-text-field
                  v-model="studentInfoForEdit.snils"
                  label="СНИЛС"
                  hint="Укажите числа СНИЛС без пробелов"
                  color="#af955c"
                  v-mask="'###-###-### ##'"
                  dense
                  :rules="[rules.snils,]"
                  :error="!studentInfoForEdit.snils"
                  required
                  :disabled="isRegistrationSuccess"
                ></v-text-field>
            </v-col>
          </v-row>
          <!--<v-row justify="center" align="center">
            <v-col cols="12" sm="10" md="12" lg="9" xl="9">
              <v-textarea
                label="Информация о себе в свободной форме"
                color="#af955c"
                auto-grow
                rows="3"
                :readonly="!isGeneralEditing"
              ></v-textarea>
            </v-col>
          </v-row>-->
          <v-row class="my-5" justify="center" align="center">
            <v-btn
              text
              :disabled="!isGeneralValid" 
              :dark="isGeneralValid"
              color="#af955c"
              @click="save"
            >
              <v-icon left dark>mdi-content-save-outline</v-icon>
              Сохранить
            </v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-row>
    <v-row class="mt-8" justify="center" align="center">
      <v-card 
        :class="$vuetify.breakpoint.mdAndUp ? 'px-1' : 'mx-3'" 
        flat
        tile 
        outlined
        :width="$vuetify.breakpoint.mdAndUp ? '80%' : '95%'"
      >

      
        <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h6 font-weight-half-medium pl-0">
            <v-icon left color="#00AEBA" size="25" class="pb-1">mdi-map-marker-outline</v-icon>
            <span>Контактная информация</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn :color="isContactsEditing ? '#e31e25' : '#7690c9'" text dark x-small :disabled="isGeneralEditing || isParentsEditing || isChangingPassword" @click="contactsEditButton">
            <v-icon left dark>{{isContactsEditing ? 'mdi-window-close' : 'mdi-pencil'}}</v-icon>
            {{isContactsEditing ? 'Отмена' : 'Редактировать'}}
          </v-btn>
        </v-app-bar>
        <v-divider />
        <div v-if="!isContactsEditing" class="pl-4 my-4">
          <span class="text-body-1 font-weight-half-medium">Адрес</span>
          <p class="text-body-1">
            {{studentFullAddress}}
          </p>
          <div>
            <span class="text-body-1 font-weight-half-medium">
              E-mail:
            </span>
            <span class="text-body-1 mb-0">
              {{(studentInfo.contacts && studentInfo.contacts.email ? studentInfo.contacts.email : 'Не указан')}}
            </span>
          </div>
          <div>
            <span class="text-body-1 font-weight-half-medium">
              Телефон:
            </span>
            <span class="text-body-1">
              {{(studentInfo.contacts && studentInfo.contacts.phone ? studentInfo.contacts.phone : 'Не указан')}}
            </span>
          </div>
        </div>



        
        <v-form v-show="isContactsEditing" class="mx-5 mt-4" v-model="isContactsValid">
          <v-row justify="center" align="center">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <v-autocomplete
                v-model="studentInfoForEdit.contacts.address"
                label="Адрес"
                hint="Введите адрес"
                :loading="isSearchLoading"
                :items="addressItems"
                item-text="fullAddress"
                item-value="id"
                no-filter
                :search-input.sync="addressSearch"
                hide-no-data
                hide-details
                :rules="[rules.required]"
                :error="!studentInfoForEdit.contacts.address"
                required
                dense
                color="#af955c"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="4" sm="4" md="4" lg="4" xl="4">
              <v-text-field
                v-model="studentInfoForEdit.contacts.house"
                :rules="[rules.required, rules.maxSymbHouse]"
                label="Номер дома"
                placeholder="Пример: 2 или 5А"
                hint="Введите номер дома"
                color="#af955c"
                :error="!studentInfoForEdit.contacts.house"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="4" md="4" lg="4" xl="4">
              <v-text-field
                v-model="studentInfoForEdit.contacts.building"
                :rules=[rules.maxSymbolBuilding]
                label="Корпус"
                placeholder="Пример: 7 или 3, лит. А"
                hint="Введите корпус/строение (при наличии)"
                color="#af955c"
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="4" md="4" lg="4" xl="4">
              <v-text-field
                v-model="studentInfoForEdit.contacts.flat"
                :rules=[rules.maxSymbolFlat]
                label="Квартира"
                placeholder="Пример: 13 или 157"
                hint="Введите номер квартиры (при наличии)"
                color="#af955c"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="start" align="center">
            <v-col cols="12" sm="6" md="6" lg="4" xl="4">
              <v-text-field
                v-model="studentInfoForEdit.contacts.email"
                :rules="[rules.required, rules.email]"
                label="E-mail"
                hint="Введите e-mail адрес"
                color="#af955c"
                dense
                :error="!studentInfoForEdit.contacts.email"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="4" xl="4">
              <v-text-field
                v-model="studentInfoForEdit.contacts.phone"
                :rules="[rules.required, rules.phone]"
                label="Телефон"
                hint="Введите номер телефона"
                v-mask="'+7(###)###-##-##'"
                color="#af955c"
                dense
                type="phone"
                :error="!studentInfoForEdit.contacts.phone"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="isContactsEditing" class="my-5" justify="center" align="center">
            <v-btn
              text
              :disabled="!isContactsValid" 
              :dark="isContactsValid"
              color="#af955c"
              @click="save"
            >
              <v-icon left dark>mdi-content-save-outline</v-icon>
              Сохранить
            </v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-row>
    <v-row class="mt-8" justify="center" align="center">
      <v-card 
        :class="$vuetify.breakpoint.mdAndUp ? 'px-1' : 'mx-3'" 
        flat 
        tile 
        outlined 
        :width="$vuetify.breakpoint.mdAndUp ? '80%' : '95%'"
      >
        <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h6 font-weight-half-medium pl-0">
            <v-icon left color="#00AEBA" size="25" class="pb-1">mdi-account-supervisor-outline</v-icon>
            <span>Родители и представители</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn :color="isParentsEditing ? '#e31e25' : '#7690c9'" text dark x-small :disabled="isContactsEditing || isGeneralEditing || isChangingPassword" @click="parentsEditButton">
            <v-icon left dark>{{isParentsEditing ? 'mdi-window-close' : 'mdi-pencil'}}</v-icon>
            {{isParentsEditing ? 'Отмена' : 'Редактировать'}}
          </v-btn>
        </v-app-bar>
        <v-divider />
        <div v-if="!isParentsEditing" class="pl-4 my-4">
          <div>
            <span class="text-body-1 font-weight-half-medium">Родитель/Представитель №1</span>
            <p class="text-body-1">
              {{parentOneFullName}}
            </p>
          </div>
          <div>
            <span class="text-body-1 font-weight-half-medium">
              E-mail:
            </span>
            <span class="text-body-1 mb-0">
              {{studentInfo.parents && studentInfo.parents.parentOne.email ? studentInfo.parents.parentOne.email : 'Не указан'}}
            </span>
          </div>
          <div>
            <span class="text-body-1 font-weight-half-medium">
              Телефон:
            </span>
            <span class="text-body-1">
              {{studentInfo.parents && studentInfo.parents.parentOne.phone ? studentInfo.parents.parentOne.phone : 'Не указан'}}
            </span>
          </div>

          <div v-if="studentInfo.parents.parentTwo.lastName" class="mt-2">
            <v-divider class="my-3" />
            <div>
              <span class="text-body-1 font-weight-half-medium">Родитель/Представитель №2</span>
              <p class="text-body-1">
                {{parentTwoFullName}}
              </p>
            </div>
            <div>
              <span class="text-body-1 font-weight-half-medium">
                E-mail:
              </span>
              <span class="text-body-1 mb-0">
                {{studentInfo.parents && studentInfo.parents.parentTwo.email ? studentInfo.parents.parentTwo.email : 'Не указан'}}
              </span>
            </div>
            <div>
              <span class="text-body-1 font-weight-half-medium">
                Телефон:
              </span>
              <span class="text-body-1">
                {{studentInfo.parents && studentInfo.parents.parentTwo.phone ? studentInfo.parents.parentTwo.phone : 'Не указан'}}
              </span>
            </div>
          </div>
        </div>
        <v-form v-else class="mx-5" v-model="isParentsValid">
          <v-row class="pt-2">
            <span class="text-button">Родитель/Представитель №1</span>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
              <v-text-field
                v-model="studentInfoForEdit.parents.parentOne.lastName"
                :rules="[rules.required, rules.maxSymbFullName]"
                label="Фамилия"
                hint="Введите фамилию"
                color="#af955c"
                dense
                required
                :error="!studentInfoForEdit.parents.parentOne.lastName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
              <v-text-field
                v-model="studentInfoForEdit.parents.parentOne.firstName"
                 :rules="[rules.required, rules.maxSymbFullName]"
                label="Имя"
                hint="Введите имя"
                color="#af955c"
                dense
                required
                :error="!studentInfoForEdit.parents.parentOne.firstName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
              <v-text-field
                v-model="studentInfoForEdit.parents.parentOne.middleName"
                :rules="[rules.maxSymbMiddleName]"
                label="Отчество"
                hint="Введите отчество"
                dense
                color="#af955c"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row justify="start" align="center">
            <v-col cols="12" sm="6" md="6" lg="4" xl="4">
              <v-text-field
                v-model="studentInfoForEdit.parents.parentOne.phone"
                label="Телефон"
                hint="Введите телефон"
                dense
                v-mask="'+7(###)###-##-##'"
                color="#af955c"
                :rules="[rules.required, rules.phone]"
                required
                :error="!studentInfoForEdit.parents.parentOne.phone"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="4" xl="4">
              <v-text-field
                v-model="studentInfoForEdit.parents.parentOne.email"
                label="E-mail"
                :rules="[rules.email]"
                hint="Введите e-mail"
                dense
                color="#af955c"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="isParentTwoVisible" class="pt-5">
            <span class="text-button">Родитель/Представитель №2</span>
          </v-row>
          <v-row
            v-if="isParentTwoVisible"
            justify="center"
            align="center"
          >
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
              <v-text-field
                v-model="studentInfoForEdit.parents.parentTwo.lastName"
                :rules="[rules.required]"
                label="Фамилия"
                hint="Введите фамилию"
                color="#af955c"
                dense
                required
                :error="!studentInfoForEdit.parents.parentTwo.lastName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
              <v-text-field
                v-model="studentInfoForEdit.parents.parentTwo.firstName"
                :rules="[rules.required]"
                label="Имя"
                hint="Введите имя"
                color="#af955c"
                dense
                required
                :error="!studentInfoForEdit.parents.parentTwo.firstName"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4" xl="4">
              <v-text-field
                v-model="studentInfoForEdit.parents.parentTwo.middleName"
                label="Отчество"
                hint="Введите отчество"
                dense
                color="#af955c"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="isParentTwoVisible" justify="start" align="center">
            <v-col cols="12" sm="6" md="6" lg="4" xl="4">
              <v-text-field
                v-model="studentInfoForEdit.parents.parentTwo.phone"
                label="Телефон"
                hint="Введите телефон"
                dense
                color="#af955c"
                v-mask="'+7(###)###-##-##'"
                :rules="[rules.required, rules.phone]"
                required
                :error="!studentInfoForEdit.parents.parentTwo.phone"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="4" xl="4">
              <v-text-field
                v-model="studentInfoForEdit.parents.parentTwo.email"
                label="E-mail"
                hint="Введите e-mail"
                :rules="[rules.email]"
                dense
                color="#af955c"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-4" justify="center" align="center">
            <v-btn
              :color="isParentTwoVisible ? '#e31e25' : '#7690c9'"
              class="ma-2"
              text
              dark
              x-small
              @click="parentButton"
            >
              <v-icon left dark>{{
                isParentTwoVisible ? "mdi-minus" : "mdi-plus"
              }}</v-icon>
              {{
                isParentTwoVisible ? "Удалить родителя" : "Добавить родителя"
              }}
            </v-btn>
          </v-row>
          <v-row justify="center" align="center" class="my-5">
            <v-btn
              text
              :disabled="!isParentsValid" 
              :dark="isParentsValid"
              color="#af955c"
              @click="save"
            >
              <v-icon left dark>mdi-content-save-outline</v-icon>
              Сохранить
            </v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-row>
    <v-row class="mt-8" justify="center" align="center">
      <v-card 
        :class="$vuetify.breakpoint.mdAndUp ? 'px-1' : 'mx-3'" 
        flat 
        tile 
        outlined 
        :width="$vuetify.breakpoint.mdAndUp ? '80%' : '95%'"
      >
        <v-app-bar flat dense color="rgba(0, 0, 0, 0)">
          <v-toolbar-title class="text-h6 font-weight-half-medium pl-0">
            <v-icon left color="#00AEBA" size="25" class="pb-1">mdi-lock-outline</v-icon>
            <span>Безопасность</span>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            :color="isChangingPassword ? '#e31e25' : '#7690c9'"
            :disabled="isContactsEditing || isGeneralEditing || isParentsEditing"
            class="ma-2"
            text
            dark
            x-small
            @click="passwordButton"
          >
            <v-icon left dark>{{isChangingPassword ? "mdi-window-close" : "mdi-lock"}}</v-icon>
            {{isChangingPassword ? "Отмена" : "Изменить пароль"}}
          </v-btn>
        </v-app-bar>
        <v-divider />
        <v-form v-if="isChangingPassword" class="mt-4 mb-4" v-model="isPasswordValid">
          <v-row class="mt-4" justify="center" align="center">
            <v-col cols="10" sm="10" md="5" lg="4" xl="4">
              <v-text-field
                v-model="security.password"
                color="#af955c"
                :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.minPassword]"
                :type="isPasswordVisible ? 'text' : 'password'"
                label="Новый пароль"
                hint="Введите новый пароль"
                dense
                required
                :error="!security.password"
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
            </v-col>
            <v-col cols="10" sm="10" md="5" lg="4" xl="4">
              <v-text-field
                v-model="security.passwordRepeated"
                color="#af955c"
                :append-icon="isPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[
                  rules.required,
                  rules.passwordMatch(
                    security.password,
                    security.passwordRepeated
                  ),
                ]"
                :type="isPasswordVisible ? 'text' : 'password'"
                label="Подтверждение пароля"
                hint="Введите новый пароль еще раз"
                required
                :error="!security.passwordRepeated"
                dense
                @click:append="isPasswordVisible = !isPasswordVisible"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="my-5" justify="center" align="center">
            <v-btn
              text
              :disabled="!isPasswordValid"
              :dark="isPasswordValid"
              color="#af955c"
              @click="changePassword"
              >
              <v-icon left dark>mdi-content-save-outline</v-icon>
              Сохранить
              </v-btn
            >
          </v-row>
        </v-form>

      </v-card>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import _debounce from "debounce";
import gendersEnum from '../model/genders';

export default {
  name: "Profile",
  props: {
    studentInfo: Object,
    rules: Object,
    formatDate: Function,
    parseDate: Function
  },
  data: () => ({
    isGeneralEditing: false,
    isParentsEditing: false,
    isContactsEditing: false,
    isGeneralValid: false,
    isContactsValid: false,
    isParentsValid: false,
    isPasswordValid: false,
    isParentTwoVisible: false,
    isPasswordVisible: false,
    isChangingPassword: false,
    dateMenu: false,
    genders: gendersEnum,

    studentInfoForEdit: {},
    security: {
      password: null,
      passwordRepeated: null
    },

    isSearchLoading: false,
    addressSearch: null,
    awaitingSearch: false,
    addressItems: [],
  }),
  computed: {
    debouncedOnChange() {
      return _debounce(this.searchAddress, 700);
    },
    studentInfoStringify() {
      return JSON.stringify(this.studentInfo);
    },
    studentInfoForEditStringify() {
      return JSON.stringify(this.studentInfoForEdit);
    },
    fullName() {
      const lastName = this.studentInfo.lastName ? this.studentInfo.lastName : 'Фамилия';
      const firstName = this.studentInfo.firstName ? this.studentInfo.firstName : 'Имя';
      const middleName = this.studentInfo.middleName ? this.studentInfo.middleName : '';
      return lastName + ' ' + firstName + ' ' + middleName;
    },
    parentOneFullName() {
      const lastName = this.studentInfo.parents.parentOne.lastName ? this.studentInfo.parents.parentOne.lastName : 'Фамилия';
      const firstName = this.studentInfo.parents.parentOne.firstName ? this.studentInfo.parents.parentOne.firstName : 'Имя';
      const middleName = this.studentInfo.parents.parentOne.middleName ? this.studentInfo.parents.parentOne.middleName : '';
      return lastName + ' ' + firstName + ' ' + middleName;
    },
    parentTwoFullName() {
      const lastName = this.studentInfo.parents.parentTwo.lastName ? this.studentInfo.parents.parentTwo.lastName : 'Фамилия';
      const firstName = this.studentInfo.parents.parentTwo.firstName ? this.studentInfo.parents.parentTwo.firstName : 'Имя';
      const middleName = this.studentInfo.parents.parentTwo.middleName ? this.studentInfo.parents.parentTwo.middleName : '';
      return lastName + ' ' + firstName + ' ' + middleName;
    },
    studentFullAddress() {
      const address = this.studentInfo.contacts.addressText + ', ';
      const house = 'дом ' + this.studentInfo.contacts.house;
      const building = this.studentInfo.contacts.building ? '/' + this.studentInfo.contacts.building : '';
      const flat = this.studentInfo.contacts.flat ? ', квартира № ' + this.studentInfo.contacts.flat : '';

      return address + house + building + flat;
    },
    concatedBirthdayInfo() {
      const birthdayDate = this.studentInfo.birthdayDateFormated ? this.studentInfo.birthdayDateFormated : 'Не указана';
      const age = this.studentInfo.age ? this.studentInfo.age : 'неизвестно';

      return birthdayDate + ' (' + age + ' лет)';
    },
    formatedSnils() {
      const snils = this.studentInfo.snils ? this.studentInfo.snils.toString() : 'Нет';
      if (snils?.length !== 11) return snils;
      const sinlsSubstr = (start, end) => snils.substring(start, end);
      return `${sinlsSubstr(0,3)}-${sinlsSubstr(3,6)}-${sinlsSubstr(6,9)} ${sinlsSubstr(9,11)}`;
    },
  },
  methods: {
    async setStudentInfoForEdit() {
      if(this.studentInfo) {
        this.studentInfoForEdit = JSON.parse(JSON.stringify(this.studentInfo));
        await this.$nextTick();

        this.studentInfoForEdit.birthdayDate = this.parseDate(this.studentInfo.birthdayDateFormated);

        if(this.studentInfoForEdit.parents.parentTwo.lastName) {
          this.isParentTwoVisible = true;
        }

        if(this.studentInfoForEdit.contacts && this.studentInfoForEdit.contacts.fullAddressText !== this.addressSearch) {
          this.addressSearch = this.studentInfoForEdit.contacts.fullAddressText;
        }
      }
    },
    async searchAddress() {
      this.isSearchLoading = true;
      try {
        let response = await axios.get(
          "/api/getAddresses?query=" + encodeURIComponent(this.addressSearch),
          {
            headers: {
              RequestVerificationToken: this.token,
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        );
        this.addressItems = response.data;
        this.isSearchLoading = false;
      } 
      catch (error) {
        this.addressItems = [];
        this.isSearchLoading = false;
      }
    },
    passwordButton() {
      this.isChangingPassword = !this.isChangingPassword;
      this.security.password = null;
      this.security.passwordRepeated = null;
    },
    generalEditButton() {
      if (!this.isGeneralEditing) {
        this.isGeneralEditing = true;
      } 
      else {
        this.isGeneralEditing = false;
        this.setStudentInfoForEdit();
      }
    },
    contactsEditButton() {
      if (!this.isContactsEditing) {
        this.isContactsEditing = true;
      } 
      else {
        this.isContactsEditing = false;
        this.setStudentInfoForEdit();
      }
    },
    parentsEditButton() {
      if (!this.isParentsEditing) {
        this.isParentsEditing = true;
      } 
      else {
        this.isParentsEditing = false;
        this.setStudentInfoForEdit();
      }
    },
    parentButton() {
      if (!this.isParentTwoVisible) {
        this.isParentTwoVisible = true;
      } 
      else {
        this.isParentTwoVisible = false;
        this.studentInfoForEdit.parents.parentTwo.lastName = null;
        this.studentInfoForEdit.parents.parentTwo.firstName = null;
        this.studentInfoForEdit.parents.parentTwo.middleName = null;
        this.studentInfoForEdit.parents.parentTwo.phone = null;
        this.studentInfoForEdit.parents.parentTwo.email = null;
      }
    },
    changePassword(){
      this.$emit('change-password', this.security.password);
      this.passwordButton();
    },
    save() {
      this.$emit('save', this.studentInfoForEdit, 'profile');
    }
  },
  watch: {
    studentInfoStringify: {
      handler(val, oldVal) {
        this.setStudentInfoForEdit();
      },
    },
    studentInfoForEditStringify: {
      handler(val, oldVal) {
        const newStudentInfo = JSON.parse(val);
        const oldStudentInfo = JSON.parse(oldVal);
        if (newStudentInfo.birthdayDate !== oldStudentInfo.birthdayDate) {
          this.studentInfoForEdit.birthdayDateFormated = this.formatDate(newStudentInfo.birthdayDate);
        }
      },
    },
    addressSearch(val) {
      if (val && val.length >= 3) {
        this.debouncedOnChange();
      }
    },
  },
  created() {
    this.setStudentInfoForEdit();
  }
};
</script>